@font-face {
    font-family: 'Arial';
    src:url("../fonts/Arial.ttf");
}

@font-face {
    font-family: 'Arial Bold';
    src:url("../fonts/Arial-bold.ttf");
}

@font-face {
	font-family: 'Avant Garde Demi';
	src: url("../fonts/avant-garde-demi.otf");
}

// ITC Avant Garde Gothic Pro Bold
$title-font: itc-avant-garde-gothic-pro, sans-serif;
$title-bold: 700;
$title-medium: 500;
$title-light: 300;

$body-font: 'Arial';
$body-font-bold: 'Arial Bold';

$color-blue: #00A5AA;
$color-grey: #4F657A;
$color-red: #E3000B;
$color-lightblue: #E8FFF5;
$color-orange: #f07d22;
$color-green: #42b284;
$color-purple: #6f1e82;
$color-darkblue: #125fab;
$color-lightpurple: #f1e9f3;

$font-primary: $title-font;

$color-primary: #661c4a;
$color-secondary: #978a37;
$color-white: #fff;
$color-black: #000;

:root {
	--font-small: 15px;

	@media(min-width:768px){
		--font-small:16px;
	}
	@media(min-width:992px){
		--font-small:17px;
	}
}

main {
	width: 100%;
	height: auto;
	position: relative;
	overflow: hidden;
}

strong {
	font-family: $title-font;
	font-weight: $title-light;
}

body.__locked {
	overflow: hidden;
}

.header {
	width: 100%;
	height: auto;
	position: relative;
	max-width: 1200px;
	padding: 1.5rem 0;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;

	@media(min-width: 992px){
		padding: 2.5rem 0;
		height: 15vh;
	}
}

.header__logo {
	width: auto;
	height: 34px;
	display: block;

	@media(min-width: 992px){
		height: 55px;
	}
	@media(min-width: 1200px){
		height: 60px;
	}

	svg {
		width: 100%;
		height: 100%;
	}
}

.feature {
	width: 100%;
	height: auto;
	position: relative;
	margin-bottom: 1.5rem;
}

.feature__wrapper {
	width: 100%;
	height: auto;
	position: relative;
	padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.feature__media {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.feature__toggle {
	position: absolute;
	bottom: 0;
	right: 0;
	padding: 0.5rem 1rem;
	background-color: $color-blue;
	outline: none;
	border: none;
	display: flex;
	align-items: center;
	// -webkit-box-shadow: 3px 4px 12px -7px rgba(0,0,0,0.75);
	// -moz-box-shadow: 3px 4px 12px -7px rgba(0,0,0,0.75);
	// box-shadow: 3px 4px 12px -7px rgba(0,0,0,0.75);
	transition: all 0.3s ease;
	z-index: 2;
	opacity: 1;

	@media(min-width:768px){
		padding: 1rem 2rem;
	}

	&:hover,
	&:focus {
		background-color: darken($color-blue, 10%);
		color: #fff;
		text-decoration: none;
	}

	&.hide {
		// display: none;
	}
}

.feature__toggleText {
	color: #fff;
	font-family: $title-font;
	font-weight: 400;
	font-size: 12px;
	margin-right: 5px;
	position: relative;

	@media(min-width:768px){
		font-size: 14px;
	}
	@media(min-width:992px){
		font-size: 16px;
	}
}

.feature__svgPlay {
	display: flex;
	justify-content: center;
	width: 15px;
	height: 15px;

	@media(min-width:768px){
		width: 20px;
		height: 20px;
	}
	&.hide {
		display: none;
	}
	svg {
		fill: #fff;
		position: relative;
		width: 100%;
		height: 100%;
	}
}
.feature__svgMute {
	display: flex;
	justify-content: flex-start;
	padding-right: 6px;
	width: 15px;
	height: 15px;

	@media(min-width:768px){
		width: 20px;
		height: 20px;
		padding-right: 8px;
	}
	&.hide {
		display: none;
	}
	svg {
		fill: #fff;
		position: relative;
		width: 100%;
		height: 100%;
	}
}

.col-12, .col-xl-11 {
	padding: 0;
}

.textblock {
	width: 100%;
	height: auto;
	position: relative;
	margin-bottom: 2.5rem;

	@media(min-width: 1200px){
		margin-bottom: 3rem;
	}

	.textblock__wrapper {
		width: 100%;
		height: auto;
		position: relative;
		margin: 0 auto;
		max-width: 900px;
		padding: 0 1.5rem;
	}

	&.center {
		.textblock__wrapper {
			text-align: center;
		}
	}
}

.footer__image {
	width: 150px;
	height: 150px;
	display: block;
	margin: 0 auto 2.5rem auto;
	background: none;

	@media(min-width: 768px){
		width: 225px;
		height: 225px;
	}
	@media(min-width: 992px){
		width: 250px;
		height: 250px;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.textblock .textblock__wrapper h2 {
	// font-family: $title-font;
	// font-weight: $title-medium;
	font-family: 'Avant Garde Demi';
	font-size: 44px;
	color: $color-blue;
	display: block;
	@media(min-width: 375px){
		font-size: 50px;
	}
	@media(min-width: 768px){
		font-size: 90px;
	}
	@media(min-width: 992px){
		font-size: 98px;
	}
	@media(min-width: 1200px){
		font-size: 102px;
	}
}
.textblock .textblock__wrapper h3 {
	font-family: $title-font;
	font-weight: $title-light;
	font-size: 31px;
	color: $color-grey;
	margin-bottom: 2rem;
	display: block;
	@media(min-width: 375px){
		font-size: 35px;
	}
	@media(min-width: 768px){
		font-size: 48px;
	}
	@media(min-width: 992px){
		font-size: 52px;
	}
	@media(min-width: 1200px){
		font-size: 55px;
	}
}
.textblock .textblock__wrapper h4,
.imageblock__text h4,
.footer__wrapper h4 {
	font-family: $title-font;
	font-weight: $title-medium;
	font-size: 18px;
	color: $color-blue;
	display: block;
	@media(min-width: 768px){
		font-size: 24px;
	}
	@media(min-width: 992px){
		font-size: 28px;
	}
	@media(min-width: 1200px){
		font-size: 32px;
	}
}
.textblock .textblock__wrapper p,
.imageblock__text p {
	font-family: $title-font;
	font-weight: $title-light;
	font-size: 16px;
	color: $color-grey;
	display: block;
	@media(min-width: 768px){
		font-size: 18px;
	}
	@media(min-width: 992px){
		font-size: 20px;
	}
	@media(min-width: 1200px){
		font-size: 22px;
	}
}

.no-margin {
	margin: 0;
}


.imageblock {
	width: 100%;
	height: auto;
	position: relative;

	&.last {
		margin-bottom: 2.5rem;

		@media(min-width: 1200px){
			margin-bottom: 3rem;
		}
	}

	.imageblock__wrapper {
		width: 100%;
		height: auto;
		position: relative;
		margin: 0 auto;
		max-width: 1100px;
		display: flex;
		flex-direction: column-reverse;

		@media(min-width: 768px){
			flex-direction: row;
		}
	}

	&.alt {
		.imageblock__wrapper {
			@media(min-width: 768px){
				flex-direction: row-reverse;
			}
		}
	}
}

.imageblock__text {
	width: 100%;
	background-color: #EEF0F2;
	padding: 5%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@media(min-width: 768px){
		width: 50%;
	}
	@media(min-width: 1200px){
		padding: 8%;
	}
}

.imageblock__header {
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 1rem;

	h4 {
		margin: 0;
		@media(max-width: 768px){
			font-size: 20px;
			font-family: 'Avant Garde Demi';
		}
	}

	span {
		margin-right: 10px;
		width: 30px;
		height: 30px;

		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.imageblock__media {
	width: 100%;
	background-color: #dddddd;
	@media(min-width: 768px){
		width: 50%;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}


.typeface-red {
	color: $color-red!important;
	font-family: 'Avant Garde Demi'!important;
}
.typeface-grey {
	color: $color-grey!important;
	font-family: 'Avant Garde Demi'!important;
}

.typeface-medium {
	font-weight: $title-medium!important;
}

.footer {
	width: 100%;
	height: auto;
	position: relative;
	padding: 2rem 2rem;

	@media(min-width: 768px){
		// padding-top: 59.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
		background: none;
		height: 500px;
		padding: 2rem 1.5rem 0 1.5rem;
		margin-top: -25px;
	}

	.footer__bg {
		width: 100%;
		height: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: top;
		}
	}

	.footer__wrapper {
		width: 100%;
		height: auto;
		margin: 0 auto;
		text-align: center;
		z-index: 2;
		position: relative;

		@media(min-width: 600px){
			width: 80%;
		}

		@media(min-width: 768px){
			position: absolute;
			top: 230px;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
		}
		@media(min-width: 992px){
			top: 250px;
		}
	}

	span {
		width: 50px;
		height: 50px;
		display: block;
		margin: 0 auto 1.5rem auto;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	h4 {
		margin-bottom: 1rem;
	}

	p {
		font-family: $title-font;
		font-weight: $title-medium;
		color: $color-grey;
		margin-bottom: 0;
		font-size: 16px;

		@media(min-width: 768px){
			font-size: 20px;
		}
		@media(min-width: 992px){
			font-size: 25px;
		}
	}
	a {
		font-family: 'Avant Garde Demi';
		color: $color-grey;
		font-size: 16px;
		transition: all 0.3s ease;
		text-decoration: none;
		position: relative;
		display: block;
		width: 100%;

		@media(min-width: 768px){
			font-size: 20px;
			width: auto;
			display: inline;
		}
		@media(min-width: 992px){
			font-size: 25px;
		}

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 0%;
			height: 2px;
			background-color: $color-blue;
			transition: all 0.3s ease;
		}

		&:hover,
		&:focus {
			color: $color-blue;

			&::before {
				width: 100%;
			}
		}
	}
}

.margin-md {
	margin-bottom: 1.5rem;
}

.place1{
	margin-bottom: 1.5rem;
}
.place2 {
	margin-bottom: 2rem;
}